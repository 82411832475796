<template>
  <div
    class="mb-12"
    :class="$vuetify.breakpoint.name === 'lg' ? 'pr-12 pl-8' : $vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs' ? 'px-1' : 'px-8'"
  >
    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <img src="@/assets/images/confirmation.png" width="250" />
            <p
              class="font-weight-bold mb-1 px-5"
              style="font-size: 1.1rem !important;"
            >
              Selamat, Anda telah berhasil memperbarui
              <span v-if="tab === 0">data franchise</span>
              <span v-if="tab === 1">password</span>
              <span v-if="tab === 2">Pin</span>
            </p>
            <p
              class="body-2 font-weight-regular"
              v-show="dialog.message">
              {{ dialog.message }}
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              :loading="process.run"
              :disabled="process.run"
              color="#d31145"
              class="text-capitalize"
              @click="toClose()"
            >
              Oke
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG NO CHANGE PROFILE ==== -->
    <v-dialog v-model="dialog.noChange" persistent width="550">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <img src="@/assets/images/logout.png" width="250" />
            <p
              class="font-weight-bold mt-3 mb-3 px-5"
              style="font-size: 1.1rem !important;"
            >
              Mohon Maaf data profile Anda tidak ada yang di ubah
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan lakukan perubahan data Franchise Anda terlebih dahulu.
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.noChange = false"
            >
              Oke
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card-text class="py-0">
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="#d31145"
        slider-size="4"
        class="hidden-arrow"
      >
        <v-tabs-slider color="#d31145"></v-tabs-slider>
        <v-tab
          v-for="(item, index) in menu"
          :key="index"
          class="text-capitalize"
        >
          <span
            class="body-2"
            :class="
              tab === index ? 'font-weight-bold color-default' : 'text-third'
            "
          >
            {{ item.label }}
          </span>
        </v-tab>
      </v-tabs>
      <v-divider />
      <v-row>
        <v-col cols="12" md="4">
          <v-card class="border-radius box-shadow" height="100%">
            <div
              class="c-p d-flex align-center justify-center"
              style="background-image: linear-gradient(to top right, rgba(0, 0, 0, .05), rgba(0, 0, 0, .05)); height: 300px;"
            >
              <v-avatar width="200" height="200" v-if="tab === 1 || tab === 2">
                <v-img :src="user.image_url">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height justify-center ma-0"
                      align="center"
                    >
                      <v-progress-circular indeterminate color="#d31145">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
              <image-profile v-if="tab === 0" v-model="avatar">
                <div
                  slot="activator"
                  class="c-p d-flex align-center justify-center"
                  style="height: 300px;"
                >
                  <v-btn
                    fab
                    elevation="1"
                    color="#d31145"
                    class="v-edit-general"
                  >
                    <v-icon color="white" size="40">
                      mdi-account-edit
                    </v-icon>
                  </v-btn>
                  <v-avatar
                    width="200"
                    height="200"
                    class="grey lighten-3"
                    v-ripple
                  >
                    <div
                      class="px-5"
                      v-if="addImage && avatar.url === undefined"
                    >
                      <v-progress-linear
                        color="#d31145"
                        indeterminate
                        rounded
                        height="6"
                        class="mx-4 mt-4"
                        style="width: 150px;"
                      >
                      </v-progress-linear>
                      <p class="mt-2 mx-4 mb-0" style="font-size: 11px;">
                        Mengupload Gambar . . .
                      </p>
                    </div>
                    <v-img
                      v-if="avatar && !addImage"
                      :src="form.profileUrlImage"
                      width="200"
                      height="200"
                    >
                    </v-img>
                    <v-img
                      v-if="!avatar && addImage === ''"
                      :src="user.image_url"
                      gradient="to top, rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.10)"
                      width="200"
                      height="200"
                    >
                      <v-icon color="white" class="w-100" large
                        >mdi-camera</v-icon
                      >
                    </v-img>
                  </v-avatar>
                </div>
              </image-profile>
            </div>
            <v-card-title class="subtitle-1 text-first">
              <span class="mr-1">{{ user.name + ' -' }}</span>
              <v-tooltip bottom class="ml-2">
                <template v-slot:activator="{ attrs, on }"> 
                  <v-chip
                    small
                    color="green"
                    class="white--text cursor-pointer"
                    @click="copyOnClipboard(user.code)"
                    v-bind="attrs"
                    v-on="on">
                    {{ user.code }}
                  </v-chip>
                </template>
                <span>Klik untuk copy</span>
              </v-tooltip>
            </v-card-title>
            <v-card-subtitle class="text-second line-text-second caption pb-0">
              {{ user.email }}
            </v-card-subtitle>
            <v-card-subtitle class="text-second line-text-second caption pt-1">
              {{ user.phone }}
            </v-card-subtitle>
          </v-card>
        </v-col>

        <!-- ==== FORM UBAH PROFILE, UBAH PASSWORD, UBAH PIN ==== -->
        <v-col cols="12" md="8">
          <v-card height="100%" class="border-radius box-shadow px-3 py-2">
            <!-- ==== TEMPLATE UBAH PROFILE ==== -->
            <div v-if="tab === 0">
              <ValidationObserver ref="observer">
                <v-form>
                  <ValidationProvider
                    name="Nama"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      outlined
                      dense
                      color="#d31145"
                      label="Nama"
                      v-model="form.profilefullName"
                      :error-messages="
                        formError.profilefullName !== ''
                          ? formError.profilefullName
                          : errors
                      "
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    name="No. Telepon"
                    rules="required|min:9|max:13"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      outlined
                      dense
                      color="#d31145"
                      label="No. Telepon"
                      :counter="13"
                      onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                      v-model="form.profileTelephone"
                      :error-messages="
                        formError.profileTelephone !== ''
                          ? formError.profileTelephone
                          : errors
                      "
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>
                  <v-alert
                    type="error"
                    v-if="!succsess"
                    text
                    dense
                    prominent
                    v-show="response.length > 0"
                    v-html="response"
                  >
                  </v-alert>
                  <v-card-actions
                    :class="$vuetify.breakpoint.name === 'xs' ? 'mt-2' : ''"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      width="150"
                      elevation="3"
                      color="#d31145"
                      class="text-capitalize white--text"
                      :disabled="process.run"
                      :loading="process.run"
                      @click="updateGeneral"
                    >
                      <v-icon small class="mr-1">mdi-check</v-icon>
                      Simpan
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </ValidationObserver>
            </div>

            <!-- ==== TEMPLATE UBAH PASSWORD ==== -->
            <div v-if="tab === 1">
              <ValidationObserver ref="observerPassword">
                <v-form>
                  <ValidationProvider
                    name="Old Password"
                    rules="required|min:6"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      class="mt-2 mb-1"
                      autocomplete="new-password"
                      outlined
                      dense
                      color="#d31145"
                      label="Password Lama"
                      v-model="form.profilePasswordOld"
                      :type="showOld ? 'text' : 'password'"
                      :append-icon="showOld ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showOld = !showOld"
                      :error-messages="
                        formError.profilePasswordOld !== ''
                          ? formError.profilePasswordOld
                          : errors
                      "
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    name="New Password"
                    vid="confirm"
                    rules="required|min:6"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      class="mt-2 mb-1"
                      autocomplete="new-password"
                      outlined
                      dense
                      color="#d31145"
                      label="Password Baru"
                      v-model="form.profilePasswordNew"
                      :type="showNew ? 'text' : 'password'"
                      :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showNew = !showNew"
                      :error-messages="errors"
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    name="Confirm New Password"
                    rules="required|min:6|confirmed:confirm"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-on:keyup.enter="updatePassword"
                      class="mt-2 mb-1"
                      autocomplete="new-password"
                      outlined
                      dense
                      color="#d31145"
                      label="Password Konfirmasi"
                      v-model="form.profilePasswordConfirm"
                      :type="showConfirm ? 'text' : 'password'"
                      :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showConfirm = !showConfirm"
                      :error-messages="errors"
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>
                  <v-alert
                    type="error"
                    v-if="!succsess"
                    text
                    dense
                    prominent
                    v-show="responsePass.length > 0"
                    v-html="responsePass"
                  >
                  </v-alert>
                  <v-card-actions
                    :class="$vuetify.breakpoint.name === 'xs' ? 'mt-2' : ''"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      width="150"
                      elevation="3"
                      color="#d31145"
                      class="text-capitalize white--text"
                      :disabled="process.run"
                      :loading="process.run"
                      @click="updatePassword"
                    >
                      <v-icon small class="mr-1">mdi-check</v-icon>
                      Simpan
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </ValidationObserver>
            </div>

            <!-- ==== TEMPLATE UBAH PIN ==== -->
            <div v-if="tab === 2">
              <ValidationObserver ref="observerPin">
                <v-form autocomplete="off">
                  <ValidationProvider
                    name="Pin Lama"
                    rules="required|min:4|max:4"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      class="mt-2 mb-1"
                      autocomplete="new-password"
                      outlined
                      dense
                      color="#d31145"
                      label="Pin Lama"
                      v-model="form.profilePinOld"
                      :type="showOldPin ? 'text' : 'password'"
                      :append-icon="showOldPin ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showOldPin = !showOldPin"
                      :error-messages="
                        formError.profilePinOld !== ''
                          ? formError.profilePinOld
                          : errors
                      "
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    name="New Pin"
                    vid="confirm"
                    rules="required|min:4|max:4"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      class="mt-2 mb-1"
                      autocomplete="new-password"
                      outlined
                      dense
                      color="#d31145"
                      label="Pin Baru"
                      v-model="form.profilePinNew"
                      :type="showNewPin ? 'text' : 'password'"
                      :append-icon="showNewPin ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showNewPin = !showNewPin"
                      :error-messages="
                        formError.profilePinNew !== ''
                          ? formError.profilePinNew
                          : errors
                      "
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    name="Confirm New Pin"
                    rules="required|min:4|max:4|confirmed:confirm"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-on:keyup.enter="updatePin"
                      class="mt-2 mb-1"
                      autocomplete="new-password"
                      outlined
                      dense
                      color="#d31145"
                      label="Pin Konfirmasi"
                      v-model="form.profilePinConfirm"
                      :type="showConfirmPin ? 'text' : 'password'"
                      :append-icon="showConfirmPin ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showConfirmPin = !showConfirmPin"
                      :error-messages="errors"
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>
                  <v-alert
                    type="error"
                    v-if="!succsess"
                    text
                    dense
                    prominent
                    v-show="responsePin.length > 0"
                    v-html="responsePin"
                  >
                  </v-alert>
                  <v-card-actions
                    :class="$vuetify.breakpoint.name === 'xs' ? 'mt-2' : ''"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="#d31145"
                      width="150"
                      depressed
                      text
                      class="text-capitalize mr-3"
                      v-if="
                        user.user_user_group_id === '2' ||
                          user.user_user_group_id === '1'
                      "
                      @click="resetPin"
                      :disabled="process.run"
                      :loading="process.run"
                    >
                      <v-icon :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 20" left>mdi-lock-reset</v-icon>
                      Reset Pin
                    </v-btn>
                    <v-btn
                      width="150"
                      elevation="3"
                      color="#d31145"
                      class="text-capitalize white--text ml-3"
                      :disabled="process.run"
                      :loading="process.run"
                      @click="updatePin"
                    >
                      <v-icon small class="mr-1">mdi-check</v-icon>
                      Simpan
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </ValidationObserver>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <v-snackbar :color="snackbar.color" v-model="snackbar.show">
      <div>{{ snackbar.text }}</div>
    </v-snackbar>
  </div>
</template>

<script>
import ImageProfile from "@/components/ImageProfile.vue";
import { TokenService } from "@/service/Storage.Service";
import { get, post, put, destroy } from "@/service/Axios";
import store from "@/store";
export default {
  data() {
    return {
      dialog: {
        snackbar: false,
        snackbarPass: false,
        success: false,
        noChange: false,
        message: ""
      },
      showOldPin: false,
      showNewPin: false,
      showConfirmPin: false,
      showOld: false,
      showNew: false,
      showConfirm: false,
      showPage: false,
      selectedPage: "",
      tab: 0,
      addImage: "",
      menu: [
        {
          label: "General",
          value: 0,
        },
        {
          label: "Password",
          value: 1,
        },
        // {
        //   label: "Pin",
        //   value: 2,
        // },
      ],
      avatar: null,
      form: {
        profileUsername: "",
        profilefullName: "",
        profileEmail: "",
        profileUrlImage: "",
        profileTelephone: "",
        profilePasswordOld: "",
        profilePasswordNew: "",
        profilePasswordConfirm: "",
        profilePinOld: "",
        profilePinNew: "",
        profilePinConfirm: "",
      },
      formError: {
        profileUsername: "",
        profilefullName: "",
        profileEmail: "",
        profileUrlImage: "",
        profileTelephone: "",
        profilePasswordOld: "",
        profilePasswordNew: "",
        profilePasswordConfirm: "",
        profilePinOld: "",
        profilePinNew: "",
        profilePinConfirm: "",
      },
      process: {
        run: false,
      },
      succsess: true,
      color: "",
      response: "",
      responsePass: "",
      responsePin: "",
      snackbar: {
        show: false,
        text: "",
        color: "success"
      }
    };
  },
  watch: {
    avatar: {
      handler: function() {
        this.form.profileUrlImage = this.avatar.url;
        this.addImage = this.avatar.add;
      },
      deep: true,
    },
  },
  components: {
    ImageProfile,
  },
  computed: {
    user() {
      return JSON.parse(TokenService.getUser());
    },
    token() {
      return this.$store.state.token;
    },
  },
  mounted() {

    this.getUser();
    // console.log(this.user);
    // this.reset()
  },
  methods: {
    copyOnClipboard(code) {
      // copy 
      navigator.clipboard.writeText(code);
      this.snackbar = {
        show: true,
        text: "Kode Franchise berhasil di Copy",
        color: "success",
      }
    },
    formUser() {
      this.form.profilefullName = this.user.name;
      this.form.profileTelephone = this.user.phone;
    },

    async getUser() {
      const response = await get(`franchise/profile`)
      let res = response.data
      if (res.status == 200) {
        let users = res.results.data
        this.form.profilefullName = users.name;
        this.form.profileTelephone = users.phone;
        this.form.profileUrlImage = users.image_url;
      }

    },

    reset() {
      (this.form.profileUsername = this.user.user_username),
        (this.form.profilefullName = this.user.user_name);
      (this.form.profileEmail = this.user.user_email),
        (this.form.profileUrlImage = this.user.image_url),
        (this.form.profileTelephone = this.user.user_mobilephone);
      this.avatar = {
        url: this.user.image_url,
      };

      this.addImage = this.user.image_url !== "" ? false : "";
    },
    resetForm() {
      this.form = {
        profileName: "",
        profilefullName: "",
        profileEmail: "",
        profileUrlImage: "",
        profileTelephone: "",
      };

      this.showPage = false;
    },
    toClose() {
      window.location.reload();
    },
    async updateGeneral() {
      if (
        this.user.name == this.form.profilefullName &&
        this.user.phone == this.form.profileTelephone &&
        this.user.image_url == this.form.profileUrlImage
      ) {
        this.dialog.noChange = true;
      } else {
        this.process.run = true;
        const isValid = await this.$refs.observer.validate();
        if (isValid) {
          await post(`franchise/profile/update`, {
            data: {
              name: this.form.profilefullName,
              phone: this.form.profileTelephone,
              image_url: this.form.profileUrlImage,
            },
          })
            .then((response) => {
              let res = response.data;
              if (res.status === 200) {
                this.succsess = true;
                this.dialog.success = true;
                TokenService.removeUser();
                TokenService.saveUser(JSON.stringify(res.results.data));
                this.process.run = false;
              } else {
                this.color = "red";
                this.process.run = false;
                this.succsess = false;
                this.response = res.msg;
                (this.formError.profileUsername = res.data.username),
                  (this.formError.profilefullName = res.data.name),
                  (this.formError.profileEmail = res.data.email),
                  (this.formError.profileUrlImage = res.data.image_url),
                  (this.formError.profileTelephone = res.data.mobilephone);
              }
            })
            .catch((error) => {
              if (error.response) {
                let errorData = error.response.data;
                let errorCode = error.response.status;
                let errorHeader = error.response.headers;

                if (errorCode === 401) {
                  this.$store.state.unauthorization = true;
                  this.process.run = false;
                  this.process.form = false;
                }
              } else {
                // this.$store.state.errorNetwork = true;
                this.process.run = false;
                this.process.form = false;
              }
            });
        }
        this.process.run = false;
      }
    },
    async updatePassword() {
      this.process.run = true;
      const isValid = await this.$refs.observerPassword.validate();
      if (isValid) {
        await post(`franchise/reset_password`, {
          data: {
            old_password: this.form.profilePasswordOld,
            new_password: this.form.profilePasswordNew,
            confirm_password: this.form.profilePasswordConfirm,
          },
        })
          .then((response) => {
            let res = response.data;
            if (res.status === 200) {
              this.succsess = true;
              this.dialog.success = true;
              this.process.run = false;
              this.form = {
                profilePasswordOld: "",
                profilePasswordNew: "",
                profilePasswordConfirm: "",
              };
              this.$refs.observerPassword.reset();
            } else {
              this.process.run = false;
              this.succsess = false;
              this.color = "red";
              this.formError.profilePasswordOld = res.results.data.old_password;
              this.responsePass = res.message;
            }
          })
          .catch((error) => {
            if (error.response) {
              let errorData = error.response.data;
              let errorCode = error.response.status;
              let errorHeader = error.response.headers;

              if (errorCode === 401) {
                this.$store.state.unauthorization = true;
                this.process.run = false;
                this.process.form = false;
              }
            } else {
              this.$store.state.errorNetwork = true;
              this.process.run = false;
              this.process.form = false;
            }
          });
      } else {
        this.process.run = false;
      }
    },
  },
};
</script>

<style scoped>
.line:hover {
  border-left: 6px solid #f0932b;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}
.background-cols {
  background-color: #ffffff;
  border: 3px solid #d31145;
  border-radius: 10px;
}
.br-20 {
  border-radius: 20px;
}
.box-pencil {
  position: absolute;
  left: 295px;
  top: 215px;
}
.card-access-profile {
  background-color: #f0932b;
}
.w-100 {
  width: 100%;
}
.v-edit-general {
  position: absolute !important;
  bottom: 150px;
  /* right: 160px; */
  z-index: 5;
  margin-left: 140px;
}
</style>

<style>
  .hidden-arrow .v-slide-group .v-slide-group__next--disabled, .v-slide-group__prev--disabled {
    display: none !important;
  }
</style>
